






















































































































import Vue from 'vue'

import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import CampaignAccountSelection from '@/components/ADSManager/NewCampaign/CampaignAccountSelection.vue'
import CampaignInitialSettings from '@/components/ADSManager/NewCampaign/CampaignInitialSettings.vue'
import MainBudgetSettings from '@/components/ADSManager/NewCampaign/Budget/MainBudgetSettings.vue'
import BudgetScheduler from '@/components/ADSManager/NewCampaign/Budget/BudgetScheduler.vue'
import Bidding from '@/components/ADSManager/NewCampaign/Budget/Bidding.vue'
import Switcher from '@/components/Switcher.vue'
import BudgetModal from '@/components/ADSManager/NewCampaign/BudgetModal.vue'
import CampaignEditFooter from '@/components/ADSManager/EditCampaign/CampaignEditFooter.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'MainSettings',
  components: {
    BaseAlert,
    CampaignAccountSelection,
    CampaignInitialSettings,
    MainBudgetSettings,
    BudgetScheduler,
    Bidding,
    Switcher,
    BudgetModal,
    CampaignEditFooter
  },
  data () {
    return {
      selected_ad_account: {},
      savingProcess: false,
      budget_types: [
        {
          id: 0,
          budget_type: '1',
          title: 'Total',
          name: 'Total'
        },
        {
          id: 1,
          budget_type: '2',
          title: 'Daily',
          name: 'Daily'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('adAccounts', [
      'getAllAccounts'
    ]),
    ...mapGetters('adsManager', [
      'getCampaign',
      'getInitCampaignData',
      'getObjective'
    ]),
    ...mapGetters('budget', [
      'getInitialBudgetsAdSetSpendCap',
      'getBudgetsAdSetSpendCap',
      'getCampaignBudgetDetailsMeta'
    ]),
    // compareObject () {
    //   const obj = {}
    //   obj['name'] = this.getCampaign.name !== this.getInitCampaignData.name
    //   obj['amount'] = this.getCampaign.amount !== this.getInitCampaignData.amount
    //   obj['budget_optimization'] = this.getCampaign.budget_optimization !== this.getInitCampaignData.budget_optimization
    //   obj['bid'] = this.getCampaign.bid !== this.getInitCampaignData.bid
    //   return obj
    // },
    initialAdSetsData () {
      return this.getInitialBudgetsAdSetSpendCap.map(v => {
        return {
          id: Number(v.id),
          budget: Number(v.amount)
        }
      })
    },
    checkCampaignBudget () {
      return this.minBudget > this.getCampaign.amount
    },
    allSavingData () {
      const obj = {
        name: this.getCampaign.name,
        use_budget_optimization: Boolean(this.getCampaign.budget_optimization),
        start_time: this.getCampaign.start_time
      }
      if (this.getCampaign.stop_time) {
        obj['end_time'] = this.getCampaign.stop_time
      }
      if (!this.getCampaign.budget_optimization) {
        obj['ad_sets_data'] = this.ad_sets_data
      } else {
        obj['amount'] = +this.getCampaign.amount
        obj['bidding'] = this.getCampaign.bidding || 'LOWEST_COST_WITHOUT_CAP'
      }
      return obj
    },
    ad_sets_data () {
      return this.getBudgetsAdSetSpendCap.length
        ? this.getBudgetsAdSetSpendCap.map(v => {
          return {
            id: Number(v.id),
            budget: Number(v.amount)
          }
        })
        : []
    },
    currency () {
      return this.getCampaignBudgetDetailsMeta ? this.getCampaignBudgetDetailsMeta?.currency?.symbol : ''
    },
    minBudget: function (): number {
      const minBudgetDailyLowFreq: number = this.getCampaignBudgetDetailsMeta
        ?.min_budgets?.min_daily_budget_low_freq

      const minBudgetHighFreq: number = this.getCampaignBudgetDetailsMeta?.min_budgets
        ?.min_daily_budget_high_freq

      const dailyBudgetImp: number = this.getCampaignBudgetDetailsMeta
        ?.min_budgets?.min_daily_budget_imp

      const period: number = this.$moment
        .duration(this.$moment(this.endDate).diff(this.$moment(this.startDate)))
        .asDays()

      const checkPeriodIsNaN = Object.is(period, NaN)
      const checkPeriodIsZero = Object.is(period, 0)

      if (String(this.getCampaign?.budget_type_time) === '1') {
        switch (this.getCampaign?.optimize_for) {
          case 'IMPRESSIONS':
          case 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS':
          case 'AD_RECALL_LIFT':
          case 'REACH':
          case 'OFFSITE_CONVERSIONS':
            if (!checkPeriodIsNaN && !checkPeriodIsZero) {
              return dailyBudgetImp * period * this.getBudgetsAdSetSpendCap.length
            } else return dailyBudgetImp * this.getBudgetsAdSetSpendCap.length
          case 'LINK_CLICKS':
          case 'PAGE_LIKES':
          case 'POST_ENGAGEMENT':
          case 'LANDING_PAGE_VIEWS':
            if (!checkPeriodIsNaN && !checkPeriodIsZero) {
              return minBudgetHighFreq * period * this.getBudgetsAdSetSpendCap.length
            } else return minBudgetHighFreq * this.getBudgetsAdSetSpendCap.length
        }

        if (!checkPeriodIsNaN && !checkPeriodIsZero) {
          return minBudgetDailyLowFreq * period * this.getBudgetsAdSetSpendCap.length
        } else return minBudgetDailyLowFreq * this.getBudgetsAdSetSpendCap.length
      } else {
        switch (this.getCampaign?.optimize_for) {
          case 'IMPRESSIONS':
          case 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS':
          case 'AD_RECALL_LIFT':
          case 'REACH':
          case 'OFFSITE_CONVERSIONS':
            return dailyBudgetImp * this.getBudgetsAdSetSpendCap.length
          case 'LINK_CLICKS':
          case 'PAGE_LIKES':
          case 'POST_ENGAGEMENT':
          case 'LANDING_PAGE_VIEWS':
            return minBudgetHighFreq * this.getBudgetsAdSetSpendCap.length
          default:
            return minBudgetDailyLowFreq * this.getBudgetsAdSetSpendCap.length
        }
      }
    },
    campaign_name: {
      get () {
        return this.getCampaign.name
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'name',
          value: v
        })
        if (this.getCampaign.name !== this.getInitCampaignData.name) {
          this.toggleChanges(true)
        }
      }
    },
    budget_type: {
      get () {
        return this.budget_types.find(v => {
          return String(v.budget_type) === String(this.getCampaign?.budget_type_time)
        })
      },
      set (v) {
        this.getCampaign({
          field: 'campaign',
          prop: 'budget_type_time',
          value: v
        })
      }
    },
    budget: {
      get () {
        return this.getCampaign?.amount
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'amount',
          value: +v
        })
        if (this.getCampaign.amount !== this.getInitCampaignData.amount) {
          this.toggleChanges(true)
        }
      }
    },
    use_budget_optimization: {
      get () {
        return this.getCampaign.budget_optimization
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'budget_optimization',
          value: Number(v)
        })
        if (this.getCampaign.budget_optimization !== this.getInitCampaignData.budget_optimization) {
          this.toggleChanges(true)
        }
      }
    },
    bid_cap: {
      get () {
        return this.getCampaign.bid
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'bid',
          value: Number(v)
        })
        if (this.getCampaign.bid !== this.getInitCampaignData.bid) {
          this.toggleChanges(true)
        }
      }
    },
    bidding: {
      get () {
        return this.getCampaign.bidding
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'bidding',
          value: v
        })
        if (this.getCampaign.bidding !== this.getInitCampaignData.bidding) {
          this.toggleChanges(true)
        }
      }
    },
    start_date: {
      get () {
        return this.getCampaign && this.getCampaign.start_time
          ? this.$moment(this.getCampaign.start_time).format('YYYY-MM-DD')
          : null
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'start_time',
          value: v + ' ' + this.start_time
        })
      }
    },
    end_date: {
      get () {
        return this.getCampaign && this.getCampaign.stop_time
          ? this.$moment(this.getCampaign.stop_time).format('YYYY-MM-DD')
          : null
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'stop_time',
          value: v + ' ' + this.end_time
        })
        if (this.getCampaign.stop_time !== this.getInitCampaignData.stop_time) {
          this.toggleChanges(true)
        }
      }
    },
    start_time: {
      get () {
        return this.getCampaign && this.$moment(this.getCampaign.start_time)._isValid
          ? this.$moment(this.getCampaign.start_time).format('HH:mm:ss')
          : '00:00:00'
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'start_time',
          value: this.start_date + ' ' + v
        })
      }
    },
    end_time: {
      get () {
        return this.getCampaign && this.$moment(this.getCampaign?.stop_time)._isValid
          ? this.$moment(this.getCampaign.stop_time).format('HH:mm:ss')
          : '00:00:00'
      },
      set (v) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'stop_time',
          value: this.end_date + ' ' + v
        })
        if (this.getCampaign.stop_time !== this.getInitCampaignData.stop_time) {
          this.toggleChanges(true)
        }
      }
    },
    campaign_objective () {
      return this.getObjective.map(o => {
        return {
          label: o.name,
          code: o.id
        }
      })
    },
    selectedObjective () {
      if (this.getCampaign) {
        return this.campaign_objective.find(v => v.code === this.getCampaign.objective)
      }
      return {}
    },
    checkOptimizations () {
      if (this.getCampaign) {
        return Boolean(this.getCampaign.budget_optimization)
      }
      return false
    },
    selectAdAccount () {
      if (this.getCampaign) {
        const adAccount = this.getAllAccounts.find(v => v.id === this.getCampaign.account_id)
        if (adAccount) {
          return {
            label: adAccount.name,
            code: adAccount.id,
            external_id: adAccount.external_id,
            currency: adAccount.currency
          }
        }
      }
      return {}
    }
  },
  methods: {
    ...mapActions('adsManager', [
      'load_campaign_objective',
      'load_campaign',
      'saving_changes_published_campaign'
    ]),
    ...mapActions('notifications', [
      'addNotification'
    ]),
    ...mapMutations('adsManager', [
      'setStateFieldProp',
      'toggleChanges'
    ]),
    changeBudget (acc) {
      this.budget = acc
    },
    checkDistributionType (type: { label: string, value: string}): void {
      this.distributionCheck = !!(type.value === 'manually' || type.value === 'proportionally')
    },
    changeTime (acc) {
      if (acc.key === 'endTime' && this.end_date) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'stop_time',
          value: this.end_date + ' ' + acc.time.format24 + ':00'
        })
      }
      if (acc.key === 'startTime' && this.start_date) {
        this.setStateFieldProp({
          field: 'campaign',
          prop: 'start_time',
          value: this.start_date + ' ' + acc.time.format24 + ':00'
        })
      }
    },
    saveCampaignChanges () {
      this.savingProcess = true
      this.saving_changes_published_campaign({
        campaignId: this.$route.params.campaign_id,
        data: this.allSavingData
      })
        .then(() => {
          this.addNotification(
            { body: 'Success', type: 'success' }
          )
          this.load_campaign({
            campaignId: this.$route.params.campaign_id,
            params: {
              'include[0]': 'ad_sets',
              'include[1]': 'ad_sets.ads',
              'include[2]': 'budget'
            }
          })
        })['catch'](() => {
          console.log('Something went wrong')
        })['finally'](() => {
          this.toggleChanges(false)
          setTimeout(() => {
            this.savingProcess = false
          }, 1000)
        })
    }
  },
  mounted () {
    this.toggleChanges(false)
    this.load_campaign_objective()
  }
})
