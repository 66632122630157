
























































































































import Vue from 'vue'

import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BaseInput from '@/components/BaseInputVuelidate.vue'

export default Vue.extend({
  name: 'CampaignInitialSettings',
  components: {
    BaseInput,
    VSelect
  },
  props: {
    type: {
      type: String
    },
    options_new_exist_ads: {
      type: Array
    },
    name: {
      default: ''
    },
    campaign_objective: {
      type: Array
    },
    selected_objective: {
      type: Object
    },
    disabledSelects: {
      type: Boolean
    }
  },
  computed: {
    campaign_name: {
      get () {
        return this.name
      },
      set (v) {
        this.$emit('change-name', v)
      }
    },
    objective: {
      get () {
        return this.selected_objective
      },
      set (v) {
        this.$emit('change-objective', v)
      }
    }
  },
  methods: {}
})
