


























































































































































import Vue from 'vue'

import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import BaseInput from '@/components/BaseInput.vue'
// import PlacementsNew from '@/components/ADSManager/NewCampaign/PlacementsNew.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import NewPlacements from '@/components/ADSManager/NewCampaign/NewPlacements.vue'
import AudienceCard from '@/components/ADSManager/NewCampaign/Audience/AudienceCard.vue'
import MainBudgetSettings from '@/components/ADSManager/NewCampaign/Budget/MainBudgetSettings.vue'
import BudgetScheduler from '@/components/ADSManager/NewCampaign/Budget/BudgetScheduler.vue'
import Bidding from '@/components/ADSManager/NewCampaign/Budget/Bidding.vue'
import Optimizing from '@/components/ADSManager/NewCampaign/Budget/Optimizing.vue'
import AdSetLimits from '@/components/ADSManager/NewCampaign/Budget/AdSetLimits.vue'
import CampaignEditFooter from '@/components/ADSManager/EditCampaign/CampaignEditFooter.vue'
import ConversionOptions from '@/components/ADSManager/NewCampaign/Budget/ConversionOptions.vue'
import FrequencyCap from '@/components/ADSManager/NewCampaign/Budget/FrequencyCap.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'EditAdSet',
  components: {
    BaseAlert,
    AudienceCard,
    BasePagePreloader,
    // PlacementsNew,
    BaseInput,
    BudgetScheduler,
    MainBudgetSettings,
    Bidding,
    Optimizing,
    NewPlacements,
    AdSetLimits,
    CampaignEditFooter,
    ConversionOptions,
    FrequencyCap
  },
  data () {
    return {
      allSavingData: {},
      savingProcess: false,
      showLimits: false,
      loading: true,
      placementType: 'custom',
      budget_types: [
        {
          id: 0,
          budget_type: '1',
          title: 'Total',
          name: 'Total'
        },
        {
          id: 1,
          budget_type: '2',
          title: 'Daily',
          name: 'Daily'
        }
      ]
    }
  },
  props: {
    campaign_id: {
      required: true
    },
    ad_set_id: {
      required: true
    }
  },
  watch: {
    ad_set_id () {
      this.loading = true
      this.toggleAdSetChanges(false)
      delete this.allSavingData['audiences_data']
      this.getAdSet()
    },
    checkLimits (v) {
      if (this.adSet.max_spend_cap && this.adSet.min_spend_target) {
        if (!v) {
          this.toggleAdSetChanges(true)
        }
      }
    }
  },
  computed: {
    ...mapGetters('adsManager', {
      campaign: 'getCampaign',
      getTargeting: 'getTargetingAudiencesData',
      adSet: 'getAdSet',
      getInitAdSetData: 'getInitAdSetData'
    }),
    ...mapGetters('budget', [
      'getCampaignBudgetDetailsMeta',
      'getCampaignBudgetDetails',
      'getAttributionSpec',
      'getMinBudgetAdSet'
    ]),
    checkLimits () {
      return this.showLimits &&
        ((+this.adSet.min_spend_target > +this.adSet.max_spend_cap && +this.adSet.max_spend_cap > 0) ||
        !!(this.campaign.budget_optimization && +this.adSet.max_spend_cap === 0))
    },
    attributionSpec () {
      return this.getAttributionSpec.find(v => v.code === this.adSet.attribution_spec_value)
    },
    start_date: {
      get () {
        return this.adSet.start_time
          ? this.$moment(this.adSet.start_time).format('YYYY-MM-DD')
          : null
      },
      set (v) {
        this.setAdSetProp({
          field: 'adSet',
          prop: 'start_time',
          value: v + ' ' + this.start_time
        })
      }
    },
    end_date: {
      get () {
        return this.adSet.end_time
          ? this.$moment(this.adSet.end_time).format('YYYY-MM-DD')
          : null
      },
      set (v) {
        this.setAdSetProp({
          field: 'adSet',
          prop: 'end_time',
          value: v + ' ' + this.end_time
        })
        if (v + ' ' + this.end_time !== this.getInitAdSetData.end_time) {
          this.toggleAdSetChanges(true)
        }
        this.allSavingData['end_time'] = v + ' ' + this.end_time
      }
    },
    start_time: {
      get () {
        return this.$moment(this.adSet.start_time)._isValid
          ? this.$moment(this.adSet.start_time).format('HH:mm:ss')
          : '00:00:00'
      },
      set (v) {
        this.setAdSetProp({
          field: 'adSet',
          prop: 'start_time',
          value: this.start_date + ' ' + v
        })
      }
    },
    end_time: {
      get () {
        return this.$moment(this.adSet.end_time)._isValid
          ? this.$moment(this.adSet.end_time).format('HH:mm:ss')
          : '00:00:00'
      },
      set (v) {
        this.setAdSetProp({
          field: 'adSet',
          prop: 'end_time',
          value: this.end_date + ' ' + v
        })
        this.allSavingData['end_time'] = this.end_date + ' ' + v
      }
    },
    // TODO: for campaign with objective REACH
    // attributionSpec: {
    //   get () {},
    //   set (v) {}
    // }
    currency () {
      return this.getCampaignBudgetDetailsMeta
        ? this.getCampaignBudgetDetailsMeta?.currency?.symbol
        : ''
    },
    optimize_for () {
      return {
        label: this.adSet.optimize_for[0] + this.adSet.optimize_for
          .toLowerCase()
          .slice(1)
          .split('_')
          .join(' '),
        code: this.adSet.optimize_for
      }
    },
    pay_for () {
      return {
        label: this.adSet.pay_for[0] + this.adSet.pay_for
          .toLowerCase()
          .slice(1)
          .split('_')
          .join(' '),
        code: this.adSet.pay_for
      }
    },
    budget_type () {
      if (this.campaign) {
        return this.budget_types.find(v => {
          return String(v.budget_type) === String(this.campaign.budget_type_time)
        })
      }
      return null
    },
    audience () {
      if (!this.adSet) {
        return null
      }
      this.adSet.formatted_targeting.locations.forEach(l => {
        l.chosen_radius = l.radius
        l.label = l.name
      })
      return {
        ...this.adSet.formatted_targeting,
        audience_size: this.adSet.audience_size,
        audiences: []
      }
    },
    adSetTargeting () {
      return this.adSet?.formatted_targeting
    },
    // adSetPlacement () {
    //   return this.adSet?.meta
    // },
    position () {
      const positions = []
      Object.keys(this.adSetTargeting.ad_placements.positions).forEach((item) => {
        positions.push(...this.adSetTargeting.ad_placements.positions[item])
      })
      return positions
    }
  },
  methods: {
    ...mapActions('adsManager', [
      'saving_changes_published_ad_set',
      'load_custom_audiences'
    ]),
    ...mapActions('notifications', [
      'addNotification'
    ]),
    ...mapActions('adsManager', [
      'load_location_types',
      'load_campaign_objective',
      'load_campaign',
      'load_ad_set',
      '_setAdSet'
    ]),
    ...mapMutations('adsManager', {
      setAdSetProp: 'setStateFieldProp',
      toggleAdSetChanges: 'toggleAdSetChanges'
    }),
    changeTime (acc) {
      if (acc.key === 'endTime' && this.end_date) {
        this.setAdSetProp({
          field: 'adSet',
          prop: 'end_time',
          value: this.end_date + ' ' + acc.time.format24 + ':00'
        })
        if (this.end_date + ' ' + acc.time.format24 + ':00' !== this.getInitAdSetData.end_time) {
          this.toggleAdSetChanges(true)
        }
        this.allSavingData['end_time'] = this.end_date + ' ' + acc.time.format24 + ':00'
      }
      if (acc.key === 'startTime' && this.start_date) {
        this.setAdSetProp({
          field: 'adSet',
          prop: 'start_time',
          value: this.start_date + ' ' + acc.time.format24 + ':00'
        })
        this.allSavingData['start_time'] = this.start_date + ' ' + acc.time.format24 + ':00'
      }
    },
    setPlacementChanges (acc) {
      if (acc.devices.length > 0) {
        this.setAdSetProp({
          field: ['adSet', 'formatted_targeting'],
          prop: 'ad_placements',
          value: acc
        })
        this.allSavingData['ad_placements'] = this.adSet.formatted_targeting.ad_placements
      } else {
        this.setAdSetProp({
          field: ['adSet', 'formatted_targeting'],
          prop: 'ad_placements',
          value: []
        })
        delete this.allSavingData['ad_placements']
      }
    },
    changeMinSpendTarget (event) {
      this.setAdSetProp({
        field: 'adSet',
        prop: 'min_spend_target',
        value: event.value
      })
      this.allSavingData['min_spend_target'] = this.adSet.min_spend_target
    },
    changeMaxSpendCap (event) {
      this.setAdSetProp({
        field: 'adSet',
        prop: 'max_spend_cap',
        value: event.value
      })
      this.allSavingData['spend_cap'] = this.adSet.max_spend_cap
    },
    changeLimitsSettings (event) {
      this.showLimits = event
      if (!event) {
        delete this.allSavingData['min_spend_target']
        delete this.allSavingData['spend_cap']
      }
    },
    changePlacementType (acc: { type: string, data: any }) {
      this.placementType = acc.type
      this.allSavingData['ad_placements'] = (acc.type === 'automatic')
        ? []
        : this.adSet.formatted_targeting.ad_placements
    },
    changeAudience (acc) {
      if (this.allSavingData['audiences_data'] &&
        JSON.stringify(this.allSavingData['audiences_data']) !== JSON.stringify([acc])) {
        this.toggleAdSetChanges(true)
      }
      this.allSavingData['audiences_data'] = JSON.parse(JSON.stringify([acc]))
    },
    changeName (acc) {
      if (this.getInitAdSetData.name !== acc.value) {
        this.toggleAdSetChanges(true)
      }
      this.setAdSetProp({
        field: 'adSet',
        prop: 'name',
        value: acc.value
      })
      this.allSavingData['name'] = this.adSet.name
    },
    changeBudget (acc) {
      if (Number(this.getInitAdSetData.budget) !== Number(acc)) {
        this.toggleAdSetChanges(true)
      }
      this.setAdSetProp({
        field: 'adSet',
        prop: 'budget',
        value: acc
      })
      this.allSavingData['budget'] = this.adSet.budget
    },
    changeOptimizeFor (acc) {
      if (this.getInitAdSetData.optimize_for !== acc.code) {
        this.toggleAdSetChanges(true)
      }
      this.setAdSetProp({
        field: 'adSet',
        prop: 'optimize_for',
        value: acc.code
      })
      this.allSavingData['optimize_for'] = this.adSet.optimize_for
    },
    changePayFor (acc) {
      if (this.getInitAdSetData.pay_for !== acc.code) {
        this.toggleAdSetChanges(true)
      }
      this.setAdSetProp({
        field: 'adSet',
        prop: 'pay_for',
        value: acc.code
      })
      this.allSavingData['pay_for'] = this.adSet.pay_for
    },
    changeBidding (acc) {
      if (this.getInitAdSetData.bidding !== acc) {
        this.toggleAdSetChanges(true)
      }
      this.setAdSetProp({
        field: 'adSet',
        prop: 'bidding',
        value: acc
      })
      this.allSavingData['bidding'] = this.adSet.bidding
    },
    changeBidCap (acc) {
      if (this.getInitAdSetData.bid_amount !== acc) {
        this.toggleAdSetChanges(true)
      }
      this.setAdSetProp({
        field: 'adSet',
        prop: 'bid_amount',
        value: acc
      })
      this.allSavingData['bid'] = this.adSet.bid_amount
    },
    getOptimizeForList (optimizeList: Array<string>): { label: string, code: string }[] {
      if (optimizeList) {
        const result = optimizeList.map(optimize => {
          const string = (
            optimize[0].toUpperCase() + optimize.toLowerCase().slice(1)
          )
            .split('_')
            .join(' ')
          return {
            label: string,
            code: optimize
          }
        })
        return result
      }
    },
    getPayForList (): void {
      return this.getCampaignBudgetDetailsMeta.pay_for[this.adSet.optimize_for].map(payFor => {
        const string = (payFor[0].toUpperCase() + payFor.toLowerCase().slice(1))
          .split('_')
          .join(' ')
        return {
          code: payFor,
          label: string
        }
      })
    },
    getAdSet () {
      return new Promise((resolve, reject) => {
        this.load_ad_set({ campaignId: this.campaign_id, adSetId: this.ad_set_id })
          .then(() => {
            this.placementType = this.adSet.formatted_targeting.ad_placements['devices'] ? 'custom' : 'automatic'
            resolve(this.adSet)
          })['finally'](() => {
            this.loading = false
          })
      })
    },
    saveAdSetChanges () {
      this.savingProcess = true
      this.saving_changes_published_ad_set({
        campaignId: this.campaign_id,
        adSetId: this.ad_set_id,
        data: this.allSavingData
      }).then(() => {
        this.toggleAdSetChanges(false)
        this.addNotification(
          { body: 'Success', type: 'success' }
        )
        this.load_ad_set({
          campaignId: this.campaign_id,
          adSetId: this.ad_set_id
        })
        this.load_campaign({
          campaignId: this.campaign_id,
          params: {
            'include[0]': 'ad_sets',
            'include[1]': 'ad_sets.ads',
            'include[2]': 'budget'
          }
        })
      })['finally'](() => {
        this.savingProcess = false
      })
    }
  },
  mounted () {
    this.loading = true
    this.getAdSet().then(() => {
      if (this.adSet.min_spend_target && this.adSet.max_spend_cap) {
        this.showLimits = this.adSet.min_spend_target && this.adSet.max_spend_cap
        this.allSavingData['min_spend_target'] = this.adSet.min_spend_target
        this.allSavingData['spend_cap'] = this.adSet.max_spend_cap
      }
    })
    this.load_custom_audiences(this.campaign_id)
    this.load_location_types(this.campaign_id)
    this.load_campaign_objective()
  }
})
