





























import Vue from 'vue'

import BaseCirclePreloader from '@/components/BaseCirclePreloader.vue'

export default Vue.extend({
  name: 'CampaignEditFooter',
  components: {
    BaseCirclePreloader
  },
  props: {
    disableSaving: {
      default: false
    },
    loadingState: {
      type: Boolean,
      default: false
    }
  }
})
