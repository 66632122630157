

















































































import Vue from 'vue'

import BaseCheckbox from '../../../components/BaseCheckbox.vue'
import TabSwitcher from '../../../components/TabSwitcher.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'

import { mapMutations, mapGetters } from 'vuex'
import _ from 'lodash'

export default Vue.extend({
  name: 'Placements',
  components: {
    TabSwitcher,
    BaseCheckbox
    // BaseAlert
  },
  props: {
    adSet: {
      type: Object
    },
    placementList: {
      required: true
    },
    placementType: {
      type: String,
      required: true
    },
    init: {
      required: true
    }
  },
  data () {
    return {
      devices: [],
      platforms: [],
      positions: [],
      menu: [
        {
          id: 0,
          name: 'custom',
          title: 'Custom',
          description: ''
        },
        {
          id: 1,
          name: 'automatic',
          title: 'Automatic',
          description: ''
        }
      ]
    }
  },
  watch: {
    placements (v) {
      this.$emit('set', v)
    }
  },
  computed: {
    placements () {
      const obj = {}
      obj['devices'] = this.devices
      obj['platforms'] = this.platforms
      obj['positions'] = this.positions
      return obj
    },
    activeTab () {
      if (this.placementType === 'automatic') {
        this.removeErrors()
      }
      return this.menu.find(menu => menu.name === this.placementType)
    },
    type () {
      return this.placementType === 'custom' ? 'Custom' : 'Automatic'
    }
  },
  methods: {
    ...mapMutations('adsManager/adDesign', [
      'removeError'
    ]),
    textTransform (text) {
      return _.capitalize(_.lowerCase(text))
    },
    removeErrors () {
      this.removeError({
        errorField: 'ad_placements.platforms'
      })
      this.removeError({
        errorField: 'ad_placements.positions'
      })
    },
    creativeFormat () {
      return this.adDesignData.creative_format === 'normal' ? 'image' : this.adDesignData.creative_format
    },
    allPlatformPositionChecker (platform) {
      return this.adSet.meta.placements['image']['positions'][platform].every(element => {
        return this.positions.includes(element)
      })
    },
    togglePosition (event, platform, position) {
      this.$emit('changed')
      if (event) {
        if (!this.platforms.find(v => v === platform)) {
          this.platforms.push(platform)
        }
        this.positions.push(position)
      } else {
        this.positions = this.positions.filter(v => v !== position)
        if (
          this.adSet.meta.placements['image']['positions'][platform]
            .filter(
              element => this.positions
                .includes(element)).length === 0) {
          const platformIndex = this.platforms.indexOf(platform)
          this.platforms.splice(platformIndex, 1)
        }
      }
    },
    toggleDevice (event, device) {
      this.$emit('changed')
      if (!this.devices.find(v => v === device)) {
        this.devices = this.devices
          ? [this.devices, ...[device]].flat()
          : [...[device]]
      } else {
        this.devices = [this.devices.filter(v => v !== device)].flat()
      }
    },
    selectAllPositions (event, platform) {
      this.removeErrors()
      this.adSet.meta.placements['image']['positions'][platform]
        .forEach(v => {
          this.togglePosition(event, platform, v)
        })
    },
    deviceChecker (device) {
      return !!this.devices.find(v => v === device)
    },
    switchPlacement (acc) {
      this.$emit('changed')
      this.$emit('change-type', {
        type: acc.activeTab.name
      })
    }
  },
  mounted () {
    this.devices = this.init['devices'] || []
    this.platforms = this.init['platforms'] || []
    if (this.init['positions']) {
      this.positions = Object.values(this.init['positions']).flat() || []
    } else {
      this.init['positions'] = []
    }
  }
})
