































































































import Vue from 'vue'
import AdsNameTemplate from '@/components/ADSManager/NewCampaign/AdDesign/AdsNameTemplate.vue'
import FacebookPage from '@/components/ADSManager/NewCampaign/AdDesign/FacebookPage.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import Media from '@/components/ADSManager/NewCampaign/AdDesign/Media.vue'
import MediaModal from '@/components/ADSManager/NewCampaign/MediaModal.vue'
import Carousel from '@/components/ADSManager/NewCampaign/Carousel/Carousel.vue'
import { IEditedCreative } from '@/models/AdsManager/AdDesignStep/IAdDesign'
import CampaignEditFooter from '@/components/ADSManager/EditCampaign/CampaignEditFooter.vue'
import EditAdCreative from '@/components/ADSManager/EditCampaign/EditAdCreative.vue'
import EditAdDestination from '@/components/ADSManager/EditCampaign/EditAdDestination.vue'

interface Data {
  loaded: boolean
  activeFrameIndex: number
  edited_creative: IEditedCreative
  frames: Array<unknown>
  call_to_actions: Array<{ key: string, value: string }>
  active_modal_tab: number
  images: Array<string>
  showPages: boolean
  edited_primary_text: string | null
  edited_headline: string | null
  edited_description: string | null
  edited_call_to_action: string | null
  edited_display_link: string | null
  edited_target_link: string | null
  edited_name: string | null
  edited_multi_share_optimized: boolean | null
  sending: boolean
  errors: []
  isReach: boolean
  addWebsiteURL: boolean
}

export default Vue.extend({
  name: 'EditAd',
  components: {
    CampaignEditFooter,
    Carousel,
    BasePagePreloader,
    AdsNameTemplate,
    FacebookPage,
    Media,
    MediaModal,
    EditAdCreative,
    EditAdDestination
  },
  data: (): Data => ({
    loaded: false,
    activeFrameIndex: 0,
    edited_creative: {},
    frames: [],
    call_to_actions: [],
    active_modal_tab: 1,
    images: [],
    showPages: false,
    edited_primary_text: null,
    edited_headline: null,
    edited_description: null,
    edited_call_to_action: null,
    edited_display_link: null,
    edited_target_link: null,
    edited_name: null,
    edited_multi_share_optimized: null,
    sending: false,
    errors: [],
    isReach: false,
    addWebsiteURL: true
  }),
  props: {
    campaign_id: {
      required: true
    },
    ad_set_id: {
      required: true
    },
    ad_id: {
      required: true
    }
  },
  watch: {
    ad_id () {
      this.resetEditableCreative()
      this.loaded = false
      this.activeFrameIndex = 0
      this.edited_creative = {}
      this.frames = []
      this.call_to_actions = []
      this.active_modal_tab = 1
      this.images = []
      this.showPages = false
      this.edited_primary_text = null
      this.edited_headline = null
      this.edited_description = null
      this.edited_call_to_action = null
      this.edited_display_link = null
      this.edited_target_link = null
      this.sending = false
      this.errors = []
      this.load_creative_wrapped()
    }
  },
  computed: {
    ...mapGetters('adsManager', {
      campaign: 'getCampaign'
    }),
    ...mapState('adsManager/adDesign', {
      media: 'media',
      editable_creative: 'editable_creative',
      adDesignServerData: 'adDesignServerData'
    }),
    disableSaving (): boolean {
      return this.sending || this.type_of_media === 'unknown' ||
      (!Object.keys(this.edited_creative).length &&
        !(this.images[0] === this.edited_creative?.media?.attachment?.md5_hash) &&
        !this.edited_primary_text &&
        (!this.edited_headline || this.edited_headline === this.editable_creative.headline) &&
        !this.edited_description &&
        !this.edited_call_to_action &&
        (!this.edited_display_link || this.edited_display_link === this.editable_creative.url.link_view) &&
        (!this.edited_target_link || this.edited_target_link === this.editable_creative.url.url) &&
        (!this.edited_name || this.edited_name === this.ad.name) &&
        (this.edited_multi_share_optimized === null))
    },
    imagesError () {
      return this.errors.find((err) => err?.source?.parameter === 'images')?.detail[0] ||
        (!this.images.length ? 'Image required' : '')
    },
    ad () {
      if (!this.campaign) {
        return null
      }
      return this.campaign.ad_sets.find((ad_set) => (
        ad_set.id === this.ad_set_id
      ))?.ads?.find((ad) => (
        ad.id === this.ad_id
      )) || null
    },
    type_of_media: {
      get (): 'carousel' | 'normal' | 'unknown' {
        return this.edited_creative.creative_format
          ? this.edited_creative.creative_format
          : this.editable_creative.creative_format || 'unknown'
      },
      set (value) {
        this.errors = []
        this.edited_creative = { ...this.edited_creative, creative_format: value }
        this.setStateFieldProp({ field: 'adDesignData', prop: 'creative_format', value })
        if (value === 'carousel') {
          this.firstEditCarousel()
          while (this.edited_creative.child_attachments.length < 2) {
            this.addCarouselItem()
          }
          this.updateFrames()
        }
      }
    },
    multi_share_optimized () {
      return this.edited_multi_share_optimized !== null
        ? this.edited_multi_share_optimized
        : this.editable_creative.multi_share_optimized || false
    },
    primary_text: {
      get (): string {
        return (this.edited_primary_text ?? this.editable_creative.ad_text) || ''
      },
      set (value): void {
        this.edited_primary_text = value
      }
    },
    headline: {
      get (): string {
        return (this.edited_headline ?? this.editable_creative.headline) || ''
      },
      set (value): void {
        this.edited_headline = value
      }
    },
    description: {
      get (): string {
        return (this.edited_description ?? this.editable_creative.description) || ''
      },
      set (value): void {
        this.edited_description = value
      }
    },
    call_to_action: {
      get (): IEditedCreative['call_to_action'] {
        return (this.edited_call_to_action || this.editable_creative.call_to_action) || {
          type: this.call_to_actions[0]?.key || this.call_to_actions[0]?.key || '',
          value: this.call_to_actions[0]?.value || ''
        }
      },
      set (value): void {
        this.edited_call_to_action = value
      }
    },
    display_link: {
      get (): string {
        return (this.edited_display_link ?? this.editable_creative?.url?.link_view) || ''
      },
      set (value): void {
        this.edited_display_link = value
      }
    },
    target_link: {
      get (): string {
        return (this.edited_target_link ?? this.editable_creative?.url?.url) || ''
      },
      set (value): void {
        this.edited_target_link = value
      }
    }
  },
  methods: {
    ...mapActions('adsManager/adDesign', {
      load_media: 'get_media',
      load_creative: 'get_ads_creative',
      update_ads_creative: 'update_ads_creative',
      get_pages: 'get_pages'
    }),
    ...mapMutations('adsManager/adDesign', ['setStateFieldProp', 'resetEditableCreative']),
    changePrimaryText (event) {
      this.primary_text = event
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('ad_text'))
    },
    changeHeadline (event) {
      this.headline = event
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('headline'))
    },
    changeDescription (event) {
      this.description = event
    },
    selectCallToAction (event) {
      this.call_to_action = event
    },
    changeTargetLink (event) {
      this.target_link = event
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('link_url.url'))
    },
    changeDisplayLink (event) {
      this.display_link = event
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('link_url.link_view'))
    },
    adsNameTemplateChange (value) {
      this.edited_name = value
    },
    uploadCarouselImage (promise) {
      promise.then((response) => {
        this.chooseCarouselImage([response.data.data[response.data.data.length - 1]])
      })
    },
    uploadNormalImage (promise) {
      promise.then((response) => {
        this.chooseNormalImage([response.data.data[response.data.data.length - 1]])
      })
    },
    chooseCarouselImage (event) {
      if (!this.edited_creative.child_attachments &&
        this.editable_creative.carousel[this.activeFrameIndex].child_attachment.media.attachment.md5_hash ===
        event[0].attributes.md5_hash) {
        return
      }
      this.firstEditCarousel()
      this.edited_creative.child_attachments[this.activeFrameIndex].image.hash = event[0].attributes.md5_hash
      this.updateFrame(this.activeFrameIndex)
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('image'))
      this.$modal.hide('media-modal')
    },
    chooseNormalImage (event) {
      if (!this.edited_creative.images &&
        this.editable_creative.media?.attachment?.md5_hash === event[0].attributes.md5_hash) {
        return
      }
      this.edited_creative.images = event[0].attributes.md5_hash
      this.updateImages()
      this.errors = this.errors.filter((err) => err?.source?.parameter !== 'images')
      this.$modal.hide('media-modal')
    },
    updateImages () {
      if (this.edited_creative.images) {
        this.images = [this.edited_creative.images]
      } else if (this.editable_creative?.media?.attachment?.md5_hash) {
        this.images = [this.editable_creative.media.attachment.md5_hash]
      } else {
        this.images = []
      }
    },
    addCarouselItem () {
      this.edited_creative.child_attachments.push({
        headline: { headline: null },
        ad_text: { ad_text: null },
        image: { hash: null },
        url: { url: null },
        call_to_actions: { type: this.call_to_actions[0].key }
      })
    },
    chooseCarouselItem (event) {
      if (this.frames[event].state === 'not-included') {
        this.firstEditCarousel()
        const l = this.edited_creative.child_attachments.length || 0
        this.addCarouselItem()
        this.activeFrameIndex = l
      } else {
        this.activeFrameIndex = event
      }
      this.updateFrames()
    },
    removeCarouselItem (event) {
      this.firstEditCarousel()
      this.edited_creative.child_attachments =
        this.edited_creative.child_attachments.filter((frame, index) => index !== event)
      if (!this.edited_creative.child_attachments[this.activeFrameIndex]) {
        this.activeFrameIndex = this.edited_creative.child_attachments.length - 1
      }
      this.updateFrames()
    },
    showMediaModal (event) {
      this.active_modal_tab = event
      this.$modal.show('media-modal')
    },
    changeCarouselHeadline (event) {
      if (!this.edited_creative.child_attachments &&
        this.editable_creative.carousel[event.activeSlideId].child_attachment.headline.headline === event.value) {
        return
      }
      this.firstEditCarousel()
      this.edited_creative.child_attachments[event.activeSlideId].headline.headline = event.value
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('headline'))
      this.updateFrame(event.activeSlideId)
    },
    changeCarouselDescription (event) {
      this.firstEditCarousel()
      this.edited_creative.child_attachments[event.activeSlideId].ad_text.ad_text = event.value
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('ad_text'))
      this.updateFrame(event.activeSlideId)
    },
    changeCarouselLink (event) {
      if (!this.edited_creative.child_attachments &&
        this.editable_creative.carousel[event.activeSlideId].child_attachment.url.url === event.value) {
        return
      }
      this.firstEditCarousel()
      this.edited_creative.child_attachments[event.activeSlideId].url.url = event.value
      this.errors = this.errors.filter(err => !err?.source?.parameter.includes('url'))
      this.updateFrame(event.activeSlideId)
    },
    changeCarouselCallToAction (event) {
      this.firstEditCarousel()
      this.edited_creative.child_attachments[event.activeSlideId].call_to_actions.type = event.value.key
      this.updateFrame(event.activeSlideId)
    },
    changeCarouselMultiShareOptimized (event) {
      this.edited_multi_share_optimized = event.target.value === 'on'
      this.$forceUpdate()
    },
    changeCarouselPrimaryText (event) {
      this.primary_text = event.value
      this.errors = this.errors.filter(err => err.source.parameter !== 'ad_text')
      this.$forceUpdate()
    },
    firstEditCarousel () {
      if (!this.edited_creative.child_attachments) {
        const editable = this.editable_creative?.carousel?.map(({ child_attachment }) => {
          return {
            headline: { headline: child_attachment.headline.headline },
            ad_text: { ad_text: child_attachment.ad_text.ad_text },
            image: { hash: child_attachment.media.attachment.md5_hash },
            url: { url: child_attachment.url.url },
            call_to_actions: { type: child_attachment.call_to_action.type }
          }
        }) || []
        this.edited_creative.creative_format = 'carousel'
        this.edited_creative.child_attachments = editable
        this.$forceUpdate()
        this.updateFrames()
      }
    },
    mapHandlerEditable ({ child_attachment }, index) {
      return {
        active: index === this.activeFrameIndex,
        ad_text: { ad_text: child_attachment.ad_text.ad_text },
        call_to_actions: {
          key: child_attachment.call_to_action.type,
          value: this.call_to_actions.find(cta => cta.key === child_attachment.call_to_action.type).value
        },
        id: index,
        state: 'included',
        headline: { headline: child_attachment.headline.headline },
        url: { url: child_attachment.url.url },
        image: { hash: child_attachment.media.attachment.md5_hash }
      }
    },
    mapHandlerEdited (frame, index) {
      return {
        active: index === this.activeFrameIndex,
        ad_text: { ad_text: frame.ad_text.ad_text },
        call_to_actions: {
          key: frame.call_to_actions.type,
          value: this.call_to_actions.find(cta => cta.key === frame.call_to_actions.type).value
        },
        id: index,
        state: 'included',
        headline: { headline: frame.headline.headline },
        url: { url: frame.url.url },
        image: { hash: frame.image?.hash || null }
      }
    },
    updateFrames () {
      if (this.type_of_media !== 'carousel') {
        return []
      }
      const source = this.edited_creative.child_attachments
        ? this.edited_creative.child_attachments
        : this.editable_creative.carousel
      const output = source
        ?.map(this.edited_creative.child_attachments
          ? this.mapHandlerEdited
          : this.mapHandlerEditable) || []
      while (output.length < 2) {
        output.push({
          active: output.length === this.activeFrameIndex,
          ad_text: { ad_text: '' },
          call_to_actions: {
            key: this.call_to_actions[0].key,
            value: this.call_to_actions[0].value
          },
          id: output.length,
          state: 'included',
          headline: { headline: '' },
          url: { url: '' },
          image: null
        })
      }
      while (output.length < 5) {
        output.push({
          active: output.length === this.activeFrameIndex,
          ad_text: { ad_text: '' },
          call_to_actions: {
            key: this.call_to_actions[0].key,
            value: this.call_to_actions[0].value
          },
          id: output.length,
          state: 'not-included',
          headline: { headline: '' },
          url: { url: '' },
          image: null
        })
      }
      this.frames = output
    },
    updateFrame (id) {
      const source = this.edited_creative.child_attachments
        ? this.edited_creative.child_attachments
        : this.editable_creative.carousel
      const result = this.edited_creative.child_attachments
        ? this.mapHandlerEdited(source[id], id)
        : this.mapHandlerEditable(source[id], id)
      const newFrames = []

      for (const frame of this.frames) {
        if (this.frames.indexOf(frame) !== id) {
          newFrames.push(frame)
        } else {
          newFrames.push(result)
        }
      }
      this.frames = newFrames
    },
    sendAdSettings () {
      this.sending = true
      // eslint-disable-next-line
      const body: IEditedCreative | any = {
        creative_format: this.type_of_media
      }
      body.ad_text = (this.primary_text ?? this.editable_creative.ad_text) || null
      body.name = this.edited_name || this.ad.name || null
      switch (this.type_of_media) {
        case 'carousel':
          body.multi_share_optimized =
            this.edited_multi_share_optimized || this.editable_creative.multi_share_optimized || false
          if (!this.edited_creative.child_attachments) {
            this.firstEditCarousel()
          }
          body.child_attachments = this.edited_creative.child_attachments || null
          break
        case 'normal':
          body.images = this.images[0] || null
          if (this.edited_description || this.editable_creative.description) {
            body.description = this.edited_description || this.editable_creative.description
          }
          if (this.addWebsiteURL) {
            body.headline = this.headline ?? null
            body.link_url = {
              url: this.target_link ?? null,
              link_view: this.display_link ?? null
            }
            body.call_to_actions = {
              type: this.edited_call_to_action?.key || this.editable_creative.call_to_action?.type || this.call_to_actions[0].key
            }
          } else {
            body.headline = []
            body.link_url = []
            body.call_to_action = []
          }
          break
      }
      this.update_ads_creative({
        campaign_id: this.campaign_id,
        ad_id: this.ad_id,
        body
      })['catch']((error) => {
        if (error?.response?.data?.errors) {
          this.errors = error.response.data.errors
        }
      })['finally'](() => { this.sending = false })
    },
    load_creative_wrapped () {
      this.load_creative({
        campaign_id: this.campaign_id,
        ad_id: this.ad_id,
        includes: ['media', 'media.attachment', 'carousel.child_attachment',
          'carousel.child_attachment.media.attachment',
          'carousel.child_attachment.headline', 'carousel.child_attachment.url',
          'carousel.child_attachment.call_to_action',
          'carousel.child_attachment.ad_text', 'carousel.multi_share_optimized',
          'call_to_action']
      })
        .then((response) => {
          if (response?.data?.meta?.call_to_actions) {
            this.call_to_actions = response.data.meta.call_to_actions
          }
          this.isReach = this.campaign.objective === 'REACH'
          if (response?.data?.data?.attributes) {
            const attr = response?.data?.data?.attributes
            this.addWebsiteURL = !this.isReach || attr.url !== null ||
              attr.description !== null || attr.call_to_action ||
              attr.headline !== null
          }
          this.updateFrames()
          this.setStateFieldProp({ field: 'adDesignData', prop: 'creative_format', value: this.type_of_media })
          this.updateImages()
        })
    },
    changeAddWebsiteUrl (value: boolean) {
      this.addWebsiteURL = value
    }
  },
  created () {
    this.load_media()
    this.get_pages({ campaignId: this.campaign_id })
      .then(() => {
        this.showPages = true
      })
    this.load_creative_wrapped()
  }
})
