

























































import Vue from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseInput from '@/components/BaseInput.vue'

export default Vue.extend({
  name: 'AdSetLimits',
  components: {
    BaseCheckbox,
    BaseInput
  },
  data () {
    return {
      showLimits: false
    }
  },
  props: {
    checkLimits: {
      type: Boolean
    },
    optimizationType: {
      default: ''
    },
    minSpendTargetValue: {
      default: ''
    },
    maxSpendCapValue: {
      default: ''
    },
    currency: {
      type: String
    }
  },
  methods: {
    changeLimitShowing (event) {
      (this as any).showLimits = event
      this.$emit('toggleLimits', (this as any).showLimits)
    }
  },
  computed: {
    minSpendTarget: {
      get () {
        return this.minSpendTargetValue
      },
      set (v) {
        this.$emit('change-min-spend-target', v)
      }
    },
    maxSpendCap: {
      get () {
        return this.maxSpendCapValue
      },
      set (v) {
        this.$emit('change-max-spend-cap', v)
      }
    }
  },
  mounted () {
    this.showLimits = !!(this.minSpendTargetValue || this.maxSpendCapValue)
  }
})
