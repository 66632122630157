




































































































import Vue from 'vue'
import Card from '@/components/Card.vue'
import BaseInput from '@/components/BaseInputVuelidate.vue'
import BaseTextarea from '@/components/BaseComponents/BaseTextArea/BaseTextArea.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

export default Vue.extend({
  name: 'EditAdCreative',
  components: {
    Card,
    BaseInput,
    BaseTextarea,
    VSelect
  },
  props: {
    primary_text: {
      type: String,
      required: true
    },
    headline: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    call_to_action: {
      type: Object,
      required: true
    },
    call_to_actions: {
      type: Array,
      required: true
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    isReach: {
      type: Boolean,
      required: true
    },
    addWebsiteURL: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    errors (): { [key in 'headline' | 'primary_text']: string } {
      const errors = {
        headline: this.errorMessages.find((err) => err.source.parameter === 'headline')?.detail[0] ||
          (!this.headline ? 'Headline field is required!' : ''),
        primary_text: this.errorMessages.find((err) => err.source.parameter === 'ad_text')?.detail[0] ||
          (!this.primary_text ? 'Primary Text field is required!' : '')
      }
      return errors
    }
  },
  methods: {
    changePrimaryText (value) {
      this.$emit('changePrimaryText', value)
    },
    changeHeadline (value) {
      this.$emit('changeHeadline', value)
    },
    changeDescription (value) {
      this.$emit('changeDescription', value)
    },
    selectCallToAction (value) {
      this.$emit('selectCallToAction', value)
    },
    changeAddWebsiteUrl (event: InputEvent) {
      if (event.target) {
        this.$emit('changeAddWebsiteUrl', (event.target as HTMLInputElement).checked)
      }
    }
  }
})
