







































import Vue from 'vue'

import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

export default Vue.extend({
  name: 'CampaignAccountSelecting',
  components: {
    VSelect
  },
  props: {
    ad_accounts_names: {
      type: Array,
      required: false
    },
    disabledSelects: {
      type: Boolean,
      required: true
    },
    selected_ad_account: {
      type: Object,
      required: true
    }
  },
  computed: {
    selected: {
      get () {
        return this.selected_ad_account
      },
      set (v) {
        this.$emit('select', v)
      }
    },
    ad_account_select_style () {
      return {
        'font-size': '0.8rem',
        'line-height': 1,
        color: this.selected_ad_account?.code ? '#808181' : '#FA1C36'
      }
    }
  }
})
