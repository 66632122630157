
















































































import Vue from 'vue'
import Card from '@/components/Card.vue'
import BaseInput from '@/components/BaseInputVuelidate.vue'
import { url } from 'vuelidate/lib/validators'
import { messages } from '@/i18n/messages'

export default Vue.extend({
  name: 'EditAdDestination',
  components: {
    BaseInput,
    Card
  },
  validations: {
    target_link: {
      url
    },
    display_link: {
      url
    }
  },
  i18n: {
    messages: messages
  },
  props: {
    target_link: {
      type: String,
      required: true
    },
    display_link: {
      type: String,
      required: true
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    addWebsiteURL: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    errors (): { [key in 'target_link' | 'display_link']: string } {
      return {
        target_link: this.errorMessages.find((err) => err?.source?.parameter === 'link_url.url')?.detail[0] ||
          (!this.target_link ? 'Target Link field is required' : ''),
        display_link: this.errorMessages.find((err) => err?.source?.parameter === 'link_url.link_view')?.detail[0] ||
          (!this.display_link ? 'Target Link field is required' : '')
      }
    }
  },
  methods: {
    changeTargetLink (value) {
      this.$emit('changeTargetLink', value)
    },
    changeDisplayLink (value) {
      this.$emit('changeDisplayLink', value)
    }
  }
})
